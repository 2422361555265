.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.content-wrapper {
  background-color: #FFFFFF;
}
.btn-secondary {
  font-size: 14px;
  background-image: linear-gradient(#B5BABE, #6C757D);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn-secondary:hover {
  background-image: linear-gradient(#A2A7AB, #616970);
}
.btn-primary {
  font-size: 14px;
  background-image: linear-gradient(#2FB3FB, #003153);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn-primary:hover {
  background-image: linear-gradient(#2AA1E1, #056AAC);
}
.btn-success {
  font-size: 14px;
  background-image: linear-gradient(#6CBF6C, #408040);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn-success:hover {
  background-image: linear-gradient(#61AB61, #397339);
}
.btn-info {
  color: #FFFFFF;
  font-size: 14px;
  background-image: linear-gradient(#0dcaf0, #055060);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn-info:hover {
  color: #FFFFFF;
  background-image: linear-gradient(#9EE9F9, #056AAC);
}
.btn-danger {
  font-size: 14px;
  background-image: linear-gradient(#E48683, #9B111E);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn-danger:hover {
  background-image: linear-gradient(#CD7875, #B42B27);
}
.btn-warning {
  font-size: 14px;
  background-image: linear-gradient(#FFC107, #B28704);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn-warning:hover {
  background-image: linear-gradient(#B28704, #CC9A05);
}
table {
  margin-bottom: 0;
  background-color: #D0DEE7;
  color: #333333;
  cursor: default;
}
table th {
  background-image: linear-gradient(#2FB3FB, #003153);
  color: #FFFFFF;
  vertical-align: middle;
  text-align: center;
  font-size: 11px;
  border-right: 1px solid #FFFFFF;
}
table th:last-child {
  border-right: none;
}
table thead tr th {
  vertical-align: middle;
}
table td {
  color: #333333;
  text-align: center;
  font-size: 11px;
  border-bottom: 1px solid #003153;
  vertical-align: middle !important;
}
.nav-tabs {
  font-size: 14px;
}
.nav-tabs .nav-link.active {
  color: #FFFFFF;
  background-color: #003153;
}
.nav-tabs .nav-link a {
  color: #003153;
}
.nav-tabs .nav-link {
  color: #003153;
}
.nav>li>a:focus, .nav>li>a:hover {
  background-color: #EEEEEE;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
